import { useEffect, useRef, useReducer } from 'react';

// IMPORT TYPES, INITIALSTATE, REDUCER
import { TYPES, initialState, sliderReducer } from './helpers';
import { useSkipIntro } from 'components/utilities';

const percent = (fraction, totality) => (100 * fraction) / totality;

export function useSlider({ duration, updateTime, currentTimeProgress }) {
	const [state, dispatch] = useReducer(sliderReducer, initialState);
	const { introEndTime } = useSkipIntro();
	const prev = useRef({});
	const containerRef = useRef(null);
	const pointRef = useRef(null);
	const adjustedDuration = Math.max(duration - introEndTime, 0);
	// this function starts dragging action
	const mouseDown = (event) => {
		const { offsetLeft } = pointRef.current;
		const { offsetWidth } = containerRef.current;

		prev.current = {
			clientX: event.clientX,
			maxInLeft: offsetLeft,
			maxInRight: offsetWidth - offsetLeft
		};

		dispatch({ type: TYPES.SET_DRAGGABLE, payload: true });
	};

	const mouseMove = (event) => {
		const { draggable } = state;

		if (draggable) {
			const { clientX: prevClientX, maxInLeft, maxInRight } = prev.current;

			const difference = event.clientX - prevClientX;
			let translateX;

			if (difference < 0 && Math.abs(difference) > maxInLeft) {
				translateX = -maxInLeft;
			} else if (difference > maxInRight) {
				translateX = maxInRight;
			} else {
				translateX = difference;
			}

			// set translateX
			dispatch({ type: TYPES.SET_TRANSLATE_X, payload: translateX });
		}
	};

	const mouseUp = (event) => {
		const { draggable, contWidth } = state;
		if (draggable) {
			const { clientX: prevClientX, maxInLeft, maxInRight } = prev.current;

			const difference = event.clientX - prevClientX;

			const newLeftPix = maxInLeft + difference;
			let newLeftPercent = 0;

			if (difference < 0 && Math.abs(difference) > maxInLeft) {
				newLeftPercent = 0;
			} else if (difference > maxInRight) {
				newLeftPercent = 100;
			} else {
				newLeftPercent = percent(newLeftPix, contWidth);
			}

			updateTime((adjustedDuration * newLeftPercent) / 100 + introEndTime);
			// set translateX
			dispatch({ type: TYPES.SET_TRANSLATE_X, payload: 0 });
			// set draggable
			dispatch({ type: TYPES.SET_DRAGGABLE, payload: false });
			// set shown percent
			dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
		}
	};

	const click = (event) => {
		const { left } = event.target.getBoundingClientRect();
		const { contWidth } = state;
		const newLeftPix = event.clientX - left;

		let newLeftPercent = percent(newLeftPix, contWidth);

		newLeftPercent = Math.min(Math.max(newLeftPercent, 0), 100);

		const newTime = (adjustedDuration * newLeftPercent) / 100 + introEndTime;
		updateTime(newTime);
		dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
	};

	const updateContainerData = () => {
		if (containerRef.current) {
			// set left percent
			const { offsetWidth } = containerRef.current;
			dispatch({ type: TYPES.SET_CONT_WIDTH, payload: offsetWidth });
		}
	};

	const onContainerMouseMove = ({ clientX }) => {
		const container = containerRef.current;
		const { left } = container.getBoundingClientRect();
		const cursorPosition = (clientX - left) / container.offsetWidth;
		// set cursor position
		dispatch({ type: TYPES.SET_CURSOR_POSITION, payload: cursorPosition });
	};

	const setPreviewVisible = (isVisible = false) => {
		dispatch({ type: TYPES.SET_PREVIEW_VISIBLE, payload: isVisible });
	};

	useEffect(() => {
		window.addEventListener('mousemove', mouseMove);
		window.addEventListener('mouseup', mouseUp);
		window.addEventListener('resize', updateContainerData);
		return () => {
			window.removeEventListener('mousemove', mouseMove);
			window.removeEventListener('mouseup', mouseUp);
			window.removeEventListener('resize', updateContainerData);
		};
	});

	useEffect(() => {
		updateContainerData();
	}, []);

	useEffect(() => {
		currentTimeProgress((currentTime) => {
			const adjustedTime = Math.max(currentTime - introEndTime, 0);
			const newLeftPercent = percent(adjustedTime, adjustedDuration);

			dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
		});
		// eslint-disable-next-line
	}, []);

	return {
		mouseDown,
		click,
		containerRef,
		pointRef,
		onContainerMouseMove,
		setPreviewVisible,
		...state
	};
}
