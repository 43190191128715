import React from 'react';
import PropTypes from 'prop-types';
// Import components
import { useSlider } from './useSlider';
import PreviewPopover from './PreviewPopover';
import {
	Root,
	StrapsContainer,
	BaseStrap,
	LoadedStrap,
	Point,
	DotContainer,
	Dot
} from './helpers';

const ShakaSlider = ({
	duration,
	updateTime,
	currentTimeProgress,
	isVisible,
	isCatchup = false,
	isPreviewAvailable = false
}) => {
	const {
		mouseDown,
		click,
		containerRef,
		pointRef,
		translateX,
		shownPercent,
		cursorPosition,
		onContainerMouseMove,
		setPreviewVisible,
		isPopoverVisible
	} = useSlider({ duration, updateTime, currentTimeProgress });

	return (
		<Root
			ref={containerRef}
			onMouseMove={onContainerMouseMove}
			onMouseEnter={() => setPreviewVisible(true)}
			onMouseLeave={() => setPreviewVisible(false)}
			isVisible={isVisible}
		>
			{isPopoverVisible && (
				<PreviewPopover
					cursorPosition={cursorPosition}
					videoDuration={duration}
					isPreviewAvailable={isPreviewAvailable}
					isCatchup={isCatchup}
				/>
			)}
			<StrapsContainer onClick={click}>
				<BaseStrap>
					<LoadedStrap percent={shownPercent} />
				</BaseStrap>
			</StrapsContainer>
			<Point ref={pointRef} translateX={translateX} leftPercent={shownPercent}>
				<DotContainer>
					<Dot onMouseDown={mouseDown} />
				</DotContainer>
			</Point>
		</Root>
	);
};

ShakaSlider.propTypes = {
	duration: PropTypes.number.isRequired,
	updateTime: PropTypes.func.isRequired,
	currentTimeProgress: PropTypes.func.isRequired,
	isCatchup: PropTypes.bool,
	isVisible: PropTypes.bool,
	isPreviewAvailable: PropTypes.bool
};

export default ShakaSlider;
