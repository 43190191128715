import { useSelector } from 'react-redux';
import { introSegmentSelector } from 'components/context/player/player_vod/selectors.js';
import { usePlayerVodContext } from 'components/context/player/player_vod';
import { isEmpty } from 'lodash';

export const useSkipIntro = () => {
	const { updateTime } = usePlayerVodContext();

	const intro = useSelector(introSegmentSelector);
	const introEndTime = intro?.end_time_in_seconds ?? 0;
	const hasIntro = !isEmpty(intro);

	const skipIntro = () => {
		if (hasIntro) {
			updateTime(introEndTime);
		}
	};

	return {
		hasIntro,
		introEndTime,
		skipIntro
	};
};
