import React from 'react';
import PropTypes from 'prop-types';

// IMPORT COMPONENTS
import {
	PreviewWrapper,
	SeekTime,
	PreviewImage,
	getPreviewData
} from './helpers';
// IMPORT HELPERS
import { getTimeLineFormat } from 'helpers/index';
import { useSkipIntro } from 'components/utilities';
// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PreviewPopover = ({
	cursorPosition: cursorPos,
	videoDuration,
	isPreviewAvailable,
	isCatchup
}) => {
	const { productID, videoId, video } = usePlayerVodContext();
	const { introEndTime } = useSkipIntro();
	const cursorPosition = cursorPos < 0 ? 0 : cursorPos;
	const seekTime = cursorPosition * (videoDuration - introEndTime);
	const timeFormated = getTimeLineFormat(seekTime);

	// calculate popover shift
	const remToSubstract = isPreviewAvailable ? 8.5 : 3.2;
	const shiftToLeft = `calc(${cursorPosition * 100}% - ${remToSubstract}rem)`;

	// get preview image
	const { imgUrl, currentFrame } = getPreviewData({
		seekTime,
		productID,
		videoId
	});

	const handlePreviewClick = () => (video.currentTime = seekTime);

	if (seekTime > videoDuration || cursorPos < 0) return null;

	const isSmall = isCatchup || !isPreviewAvailable;

	return (
		<PreviewWrapper
			shiftToLeft={shiftToLeft}
			isCatchup={isCatchup}
			isSmall={isSmall}
		>
			{isPreviewAvailable && (
				<PreviewImage
					imgUrl={imgUrl}
					currentFrame={currentFrame}
					onClick={handlePreviewClick}
				/>
			)}
			<SeekTime isSmall={isSmall}>{timeFormated}</SeekTime>
		</PreviewWrapper>
	);
};

PreviewPopover.propTypes = {
	cursorPos: PropTypes.number,
	videoDuration: PropTypes.number,
	isPreviewAvailable: PropTypes.bool,
	isCatchup: PropTypes.bool,
	cursorPosition: PropTypes.number
};

export default PreviewPopover;
