import React from 'react';
import PropTypes from 'prop-types';
// Import helpers
import { getTimeLineFormat } from 'helpers/index';
import { useSkipIntro } from 'components/utilities/hooks/player/useSkipIntro';
// Import utilities
import { useSafeMountDispatch } from 'components/utilities';

// Import components
import { Timer } from './styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PlayerTimer = () => {
	const [time, setTime] = React.useState('00:00:00');
	const { introEndTime } = useSkipIntro();
	const { currentTimeProgress } = usePlayerVodContext();
	const safeSetTime = useSafeMountDispatch(setTime);

	React.useEffect(() => {
		currentTimeProgress((currentTime) => {
			const adjustedTime = Math.max(currentTime - introEndTime, 0);
			safeSetTime(getTimeLineFormat(adjustedTime));
		});

		// eslint-disable-next-line
	}, []);

	return <Timer>{time}</Timer>;
};
PlayerTimer.propTypes = {
	introEndTime: PropTypes.number.isRequired
};

export default PlayerTimer;
