import React from 'react';
import { usePlayerVodContext } from 'components/context/player/player_vod';
import { bool, number, func } from 'prop-types';
import styled, { css } from 'styled-components';

// Import styles
import { size, customResponsive } from 'components/styles';

// Import components
import PlayController from './play _controller/PlayController';
import VolumeController from './volume_controller/VolumeController';
import PlayerSeekController from './player_seek_controller/PlayerSeekController';
import SettingsController from './settings_controller/SettingsController';
import PlayerSeriresControllers from './player_serires_controller/PlayerSeriresControllers';
import PlayerTimer from './player_timer/PlayerTimer';
import ShakaSlider from 'components/elements/organisms/player_vod/PlayerWrapper/components/ShakaSlider';

export const PlayerControllers = React.memo(
	({
		isFocus,
		isFullScreen,
		isEpisode,
		isEpisodeAvaiable,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable
	}) => {
		const {
			floatMode: { isFloatMode },
			isLiveEvent
		} = usePlayerVodContext();

		const renderSeekControllers = () =>
			!isEpisode ? (
				<PlayerSeekController />
			) : (
				<PlayerSeriresControllers isEpisodeAvaiable={isEpisodeAvaiable} />
			);

		const isPlayerModeVisible = !isFloatMode || isFullScreen;
		const isFloatModeVisible = isFloatMode && !isFullScreen;

		return (
			<Wrapper isFloatMode={isFloatModeVisible} isFocus={isFocus}>
				<Container isLiveEvent={isLiveEvent} isVisible={!isPlayerModeVisible}>
					<PlayController />
					{!isLiveEvent && renderSeekControllers()}
				</Container>

				{!isLiveEvent && (
					<ShakaSlider
						isVisible={!isPlayerModeVisible}
						duration={duration}
						updateTime={updateTime}
						currentTimeProgress={currentTimeProgress}
						isPreviewAvailable={isPreviewAvailable}
					/>
				)}

				<Container
					isLiveEvent={isLiveEvent}
					isVisible={!isPlayerModeVisible}
					right
				>
					{!isLiveEvent && <PlayerTimer />}
					<VolumeController />
					<SettingsController />
				</Container>

				<Container isVisible={!isFloatModeVisible} isFloatMode={isFloatMode}>
					<PlayController />
					<VolumeController />
					<SettingsController />
				</Container>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

PlayerControllers.propTypes = {
	isFocus: bool.isRequired,
	isFullScreen: bool.isRequired,
	isEpisode: bool.isRequired,
	isEpisodeAvaiable: bool.isRequired,
	isPreviewAvailable: bool,
	duration: number.isRequired,
	updateTime: func.isRequired,
	currentTimeProgress: number.isRequired
};

export default PlayerControllers;

const Wrapper = styled.div`
	height: 16rem;
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 999;
	display: flex;
	justify-content: space-between;
	align-items: center;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	display: ${({ isFocus }) => (isFocus ? 'flex' : 'none')};
	transition: opacity 0.3s ease-in-out;

	${customResponsive(
		size.small,
		css`
			height: 7rem;
		`
	)}

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			height: 6rem;
			align-items: flex-end;

			button {
				svg {
					height: 2.6rem;
					width: 2.6rem;
				}
			}
		`}
`;

const Container = styled.div`
	display: grid;
	align-items: center;
	justify-items: center;

	${({ right, isFloatMode }) =>
		right
			? css`
					right: 0;
					padding: 0 4rem 0 0;
					grid-template-columns: ${({ isLiveEvent }) =>
						!isLiveEvent ? `8rem 4rem 4rem` : `8rem 4rem`};
					grid-gap: 1rem;

					${customResponsive(
						size.small,
						css`
							padding: 0 0.5rem 0 0;
						`
					)}
			  `
			: css`
					left: 0;
					padding: 0 0 0 4rem;
					grid-template-columns: ${({ isLiveEvent }) =>
						!isLiveEvent ? `4rem 4rem 4rem` : `4rem 4rem`};
					grid-gap: 2rem;

					${customResponsive(
						size.small,
						css`
							grid-template-columns: ${isFloatMode ? '4rem 4rem 4rem' : '4rem'};
							padding: 0 0 0 0.5rem;
						`
					)}
			  `}

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			align-items: flex-end;
			padding: 0 0 2rem 2rem;
			grid-template-columns: 4rem 4rem 4rem;
			grid-gap: 1rem;
		`}


		${({ isVisible }) =>
		isVisible &&
		css`
			display: none;
		`}
`;
